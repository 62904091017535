<template>
  <div class="Notify-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="notificationLink">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('NOTIFICATION_DETAILS_COMPONENT_BACK_TO_NOTIFICATIONS') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group">
                  <!-- <CCol lg="2" md="2">
                    <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_TYPE') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_TYPE" rules="required" v-slot="{ errors }">
                      <select v-model="notificationDetails.type" class="form-control">
                        <option value>{{ $t('NOTIFICATION_DETAILS_COMPONENT_CHOSE_A_STATUS_LABEL') }}</option>
                        <option v-for="item in typeList" :key="item.value" :value="item.value">{{ item.label }}</option>
                      </select>
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{ errors[0] }}</span>
                      </span>
                    </ValidationProvider>
                  </CCol> -->
                  <CCol lg="2" md="2">
                    <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_ACTIVE') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="btn-switch-wrapper">
                      <label class="btn-switch">
                        <input type="checkbox" class="checkbox" v-model="notificationDetails.actived_flg" :disabled="isActive" />
                        <span class="check-silder"></span>
                      </label>
                    </div>
                  </CCol>
                </CRow>
                <!-- <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_EVENT') }}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <ValidationProvider name="NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_EVENT" rules="required" v-slot="{ errors }">
                      <select v-model="notificationDetails.event" class="form-control">
                        <option value>{{ $t('NOTIFICATION_DETAILS_COMPONENT_CHOSE_A_STATUS_LABEL') }}</option>
                        <option v-for="item in eventList" selected :key="item.value" :value="item.value">{{ item.label }}</option>
                      </select>
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{ errors[0] }}</span>
                      </span>
                    </ValidationProvider>
                  </CCol>
                </CRow> -->
                <CRow>
                  <CCol lg="12" md="12">
                    <ValidationProvider name="NOTIFICATION_DETAILS_COMPONENT_DESCRIPTION_FIELD_NAME" rules="max:1000" v-slot="{ errors }">
                      <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_DESCRIPTION_FIELD_NAME') }}:</label>
                      <textarea v-model="notificationDetails.description" class="form-control" rows="3"></textarea>
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{ errors[0] }}</span>
                      </span>
                    </ValidationProvider>
                  </CCol>
                </CRow>
                <CTabs class="mt-3" variant="tabs" :active-tab="0">
                  <CTab :title="$t('NOTIFICATION_DETAILS_COMPONENT_ENGLISH_TAB_TITLE')">
                    <div class="mt-3">
                      <CRow class="form-group">
                        <CCol>
                          <ValidationProvider
                            name="NOTIFICATION_DETAILS_COMPONENT_TITLE_FIELD_NAME"
                            rules="required|max:100"
                            v-slot="{ errors }"
                          >
                            <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_TITLE_FIELD_NAME') }}:</label>
                            <CInput v-model="notificationDetails.title" />
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </ValidationProvider>
                        </CCol>
                        <CCol lg="12" md="12">
                          <ValidationProvider name="NOTIFICATION_DETAILS_COMPONENT_CONTENT_FIELD_NAME" rules="required" v-slot="{ errors }">
                            <div>
                              <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_CONTENT_FIELD_NAME') }}:</label>
                              <yimo-vue-editor v-model="notificationDetails.content"></yimo-vue-editor>
                              <span class="error-msg-wrap">
                                <span class="error-msg">{{ errors[0] }}</span>
                              </span>
                            </div>
                          </ValidationProvider>
                        </CCol>
                      </CRow>
                    </div>
                  </CTab>
                  <CTab :title="$t('NOTIFICATION_DETAILS_COMPONENT_JAPANESE_TAB_TITLE')">
                    <div class="mt-3">
                      <CRow class="form-group">
                        <CCol>
                          <ValidationProvider name="NOTIFICATION_DETAILS_COMPONENT_TITLE_JA_FIELD_NAME" rules="max:100" v-slot="{ errors }">
                            <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_TITLE_JA_FIELD_NAME') }}:</label>
                            <CInput v-model="notificationDetails.title_ja" />
                            <span class="error-msg-wrap">
                              <span class="error-msg">{{ errors[0] }}</span>
                            </span>
                          </ValidationProvider>
                        </CCol>
                        <CCol lg="12" md="12">
                          <div>
                            <label class>{{ $t('NOTIFICATION_DETAILS_COMPONENT_CONTENT_LABEL') }}:</label>
                            <yimo-vue-editor v-model="notificationDetails.content_ja"></yimo-vue-editor>
                          </div>
                        </CCol>
                      </CRow>
                    </div>
                  </CTab>
                </CTabs>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton @click="cancel(dirty)" class="btn btn-secondary btn-cancel">{{
                      $t('NOTIFICATION_DETAILS_COMPONENT_BUTTON_CANCEL')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="!isEdit && isAllowedCreate" color="primary" class="btn btn-update btn-create">{{
                      $t('NOTIFICATION_DETAILS_COMPONENT_BUTTON_SAVE')
                    }}</CButton>

                    <CButton @click="onSubmit()" v-if="isEdit && isAllowedUpdating" color="primary" class="btn btn-update">{{
                      $t('NOTIFICATION_DETAILS_COMPONENT_BUTTON_UPDATE')
                    }}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>

            <CModal class="leave-page-modal" :show.sync="warningLeavePage" :centered="true">
              <div class>
                <div class="tit-leave">
                  <h4>{{ $t('NOTIFICATION_DETAILS_COMPONENT_LABEL_MODAL_TITLE_LEAVE') }}</h4>
                </div>
                <p class="text-modal">{{ $t('NOTIFICATION_DETAILS_COMPONENT_MODAL_CONTENT_UNSAVED_CHANGES') }}</p>
                <p class="text-modal">{{ $t('NOTIFICATION_DETAILS_COMPONENT_MODAL_QUESTION_UNSAVED_CHANGES') }}</p>
              </div>

              <template #footer>
                <CButton class="btn btn-stay" @click="warningLeavePage = false" color="dark" variant="outline">{{
                  $t('NOTIFICATION_DETAILS_COMPONENT_BUTTON_STAY_ON_THIS_PAGE')
                }}</CButton>

                <router-link class="btn btn-leave" :to="notificationLink">{{
                  $t('NOTIFICATION_DETAILS_COMPONENT_TEXT_LEAVE_THIS_PAGE')
                }}</router-link>
              </template>
            </CModal>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import StakingExplorerLink from '@/components/StakingExplorerLink';
import StakingCurrency from '@/components/StakingCurrency';
import StakingBadge from '@/components/StakingBadge';
import { MembershipOrderStatus } from '@/enums';
import { ClaimRequestStatus } from '@/enums';
import { Constants, env } from '@/constants';
import YimoVueEditor from 'yimo-vue-editor';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'EditNotification',
  components: {
    VclList,
    YimoVueEditor,
  },
  data() {
    return {
      NoId: null,
      notificationDetails: {
        title: '',
        title_ja: '',
        description: '',
        type: '',
        content: '',
        content_ja: '',
        actived_flg: false,
        event: '',
        sent_all_flg: false,
      },
      isLoading: true,
      isAllowedUpdating: false,
      isAllowedCreate: false,
      isEdit: false,
      warningLeavePage: false,
      notificationLink: '/admin/setting/notification',
      eventList: [],
      typeList: [],
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const id = this.$route.params.id;
    this.checkPermissions();
    this.typeList = this.$store.state.notification.notificationType;
    this.eventList = this.$store.state.notification.notificationEvent;

    if (id) {
      this.isEdit = true;
      this.id = id;
      this.getNotificationDetails(id);
    } else {
      this.isEdit = false;
      this.notificationDetails.type = 'SYSTEM';
      this.notificationDetails.event = 'NEW_INFORMATION';
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'UPDATE_NOTIFICATION') {
            this.isAllowedUpdating = true;
          }
          if (item.name === 'CREATE_NOTIFICATION') {
            this.isAllowedCreate = true;
          }
        });
      }
    },
    async getNotificationDetails(id) {
      try {
        const res = await this.$http.get(endpoints.getNotificationDetails(id));
        this.notificationDetails = res.data;
        this.isLoading = false;
        this.isActive = this.notificationDetails.actived_flg;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      // Trim html
      this.notificationDetails.content_ja = stringHelper.trimHtml(this.notificationDetails.content_ja);
      const content = stringHelper.trimHtml(this.notificationDetails.content);

      if (!content) {
        this.notificationDetails.content = content;
        return;
      }

      if (this.isEdit) {
        return this.updateNotification();
      }

      this.addNotification();
    },
    async updateNotification() {
      if (this.notificationDetails.isProcessing) {
        return;
      }

      this.notificationDetails.isProcessing = true;

      try {
        const data = {
          title: this.notificationDetails.title,
          title_ja: this.notificationDetails.title_ja || ' ',
          description: this.notificationDetails.description || '',
          content: this.notificationDetails.content,
          type: this.notificationDetails.type || null,
          content_ja: this.notificationDetails.content_ja || ' ',
          actived_flg: this.notificationDetails.actived_flg || false,
          event: this.notificationDetails.event || null,
          sent_all_flg: this.notificationDetails.sent_all_flg || false,
        };
        await this.$http.put(endpoints.editNotification(this.id), data);
        this.getNotificationDetails(this.id);
        this.notificationDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('NOTIFICATION_DETAILS_COMPONENT_UPDATE_NOTIFICATION_SUCCESS'),
        });
      } catch (err) {
        this.notificationDetails.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async addNotification() {
      if (this.notificationDetails.isProcessing) {
        return;
      }

      this.notificationDetails.isProcessing = true;
      try {
        const data = {
          title: this.notificationDetails.title,
          title_ja: this.notificationDetails.title_ja || ' ',
          description: this.notificationDetails.description || '',
          content: this.notificationDetails.content,
          type: this.notificationDetails.type || null,
          content_ja: this.notificationDetails.content_ja || ' ',
          actived_flg: this.notificationDetails.actived_flg || false,
          event: this.notificationDetails.event || null,
          sent_all_flg: this.notificationDetails.sent_all_flg || false,
        };
        await this.$http.post(endpoints.createNotification, data);

        this.notificationDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_SUCCESS_TITLE'),
          text: this.$t('NOTIFICATION_DETAILS_COMPONENT_CREATE_NOTIFICATION_SUCCESS'),
        });

        this.$router.push(this.notificationLink);
      } catch (err) {
        this.notificationDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NOTIFICATION_DETAILS_COMPONENT_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.notificationLink);
      }
    },
  },
};
</script>
<style lang="scss">
.Notify-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
    .tab-content {
      padding: 10px 10px 0 10px;
    }

    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
